import colors                      from "$scss/_colors.module.scss";
import styleVars                   from "$scss/_variables.module.scss";
import IInputFlags                 from "$models/IInputFlags";
import EMoleculeSize               from "$types/EMoleculeSize";
import {rgba}                      from "$utils/formatters";
import styled                      from "styled-components";
import {ECallState, PaymentStatus} from "$models/ITicket";

export const HeaderH1 = styled.h1`
  font-weight: 600;
  font-size: 24px;
  margin: 0;
`
export const InputWrapper = styled.div<{ isDisabled?: boolean } & IInputFlags>`
  border: 1px solid ${(props: IInputFlags) => props.error ? colors.red : colors.darkgray};
  background-color: ${(props: IInputFlags) => props.error ? colors.redLighten5 : colors.white};
  max-width: 100%;
  border-radius: ${styleVars.borderRadius};

  &:hover {
    border-color: ${(props: IInputFlags) => props.error ? colors.red : colors.black};
  }

  &:focus-within {
    border-color: ${({error}: IInputFlags) => error ? colors.red : colors.dodgerblue};
    box-shadow: 0 0 0 4px ${({error}: IInputFlags) => error
      ? rgba(colors.red, "10%")
      : rgba(colors.dodgerblue, "10%")};
    background-color: ${({error}: IInputFlags) => error && colors.white};
  }

  ${({isDisabled, error}: { isDisabled?: boolean } & IInputFlags) => isDisabled && `
     pointer-events: none;
     border-color: ${error ? colors.redLighten3 : colors.lightgray};
     background-color: ${error ? rgba("#ffd5d3", "40%") : colors.whitesmoke};
  `};
`
export const Hint = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  margin-top: .8rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${({isDisabled}: { isDisabled?: boolean }) => isDisabled ? colors.lightgray : colors.darkgray};
`

export const StyledTh = styled.th<{ width?: string, children?: React.ReactNode }>`
  width: ${({width}: { width?: string }) => width ? width : "15rem"};
`

export const ContentHeader = styled.h3`
  color: ${colors.black};
  font-size: 1.6rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4rem;
  margin-bottom: 1.6rem;
`

export const ContentContainer = styled.div`
  margin-bottom: 4rem;
`

export const UserAvatar = styled.img`
  height: 4.8rem;
  width: 4.8rem;
`

export const UserStatus = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: -4px;
    bottom: -4px;
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    background-color: ${({online}: { online: boolean }) => online ? colors.limegreen : colors.red};
  }
`

export const StyledTd = styled.td`
  width: ${({width}: { width?: string }) => width ? width : "auto"};
`

export const ErrorMessage = styled(Hint)`
  color: ${colors.red};
`

export const ContentHolder = styled.div`
  grid-column: contentLeft / contentRight;
  grid-row: contentTop / contentBottom;
`

export const Thumbnail = styled.img`
  width: ${({size}: {
    size?: number | string
  }) => size ? `${typeof size === "string" ? size : `${size / 10}rem`}` : "100%"};
  height: ${({size}: {
    size?: number | string
  }) => size ? `${typeof size === "string" ? size : `${size / 10}rem`}` : "100%"};
  object-fit: cover;
  background: #C4C4C4;
  border-radius: 8px;
`

export const Hr = styled.hr`
  width: 100%;
  border: none;
  height: 1px;
  background-color: ${colors.whitesmoke};
`

interface ICardProps {
  maxWidth?: number,
  width?: number,
  maxHeight?: number,
  height?: number
}

export const ReadOnlyCheckbox = styled.div`
  font-size: 1.4rem;
  position: relative;
  padding-left: 2.4rem;

  &:before {
    content: "${({checked}: { checked: boolean }) => checked ? "check_box" : "check_box_outline_blank"}";
    font-size: 2rem;
    position: absolute;
    height: 1em;
    line-height: 1em;
    top: calc(50% - .5em);
    left: 0;
    font-family: "Material Icons";
    color: ${({checked}: { checked: boolean }) => checked ? colors.dodgerblue : colors.black}
  }
`

export const TableHeader = styled.div`
  border-bottom: 1px solid ${colors.borderColor};
  padding: 1.2rem;
  font-size: 1.4rem;
  font-weight: 600;
`

export const Card = styled.div`
  overflow: hidden;
  background-color: ${colors.white};
  border: 1px solid ${colors.borderColor};
  border-radius: ${styleVars.borderRadius};
  max-width: ${({maxWidth}: ICardProps) => maxWidth ? `${maxWidth}px` : "100%"};
  max-height: ${({maxHeight}: ICardProps) => maxHeight ? `${maxHeight}px` : "unset"};
  width: ${({width}: ICardProps) => width ? `${width}px` : "auto"};
  height: ${({height}: ICardProps) => height ? `${height}px` : "auto"};
`

export const ElementInput = styled.input<{ inputSize?: EMoleculeSize } & IInputFlags>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  border: none;
  box-shadow: none;
  width: 95%;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: -0.01em;
  background-color: transparent;
  outline: none;
  caret-color: ${colors.black};
  color: ${(props: IInputFlags) => props.error ? colors.red : colors.black};

  ${({inputSize}: { inputSize?: EMoleculeSize }) => {
    if (inputSize === EMoleculeSize.Extra_Large) {
      return `
        padding: 2.3rem 1.6rem;
      `
    }
    if (inputSize === EMoleculeSize.Large) {
      return `
        padding: 1.8rem 1.6rem;
      `
    }
    if (inputSize === EMoleculeSize.Medium) {
      return `
        padding: 1.4rem;
        font-size: 1.6rem;
      `
    }
    if (inputSize === EMoleculeSize.Small) {
      return `
        padding: 1rem 1.6rem;
        font-size: 1.6rem;
      `
    }
    return `
      padding: .6rem 1.6rem;
      font-size: 1.4rem;
    `
  }};

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &:focus {
    &::placeholder {
      color: ${colors.lightgray};
    }
  }

  &:hover {
    &::placeholder {
      color: ${({error}: IInputFlags) => error ? colors.redLighten2 : colors.lightgray};
    }
  }

  &::placeholder {
    color: ${({error}: IInputFlags) => error ? colors.redLighten3 : colors.lightgray};
  }

  &:disabled {
    &::placeholder {
      color: ${({error}: IInputFlags) => error ? colors.redLighten4 : colors.lightgray};
    }
  }
`

export const StyledLabel = styled.label`
  text-transform: uppercase;
  font-size: 1.2rem;
  color: ${colors.darkgray};
  font-weight: 600;
  margin-bottom: .8rem;
  display: block;
`
export const PhoneTag = styled.span`
  display: inline-block;
  padding: .2rem .4rem;
  border-radius: .4rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  background-color: ${colors.limegreenLighten4};
  color: ${colors.limegreenDarken4};
`

export const GalleryCaptionWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  color: #fff;
  text-shadow: 0 1px 1px #000;
  font-weight: bold;
  font-size: 20px;
`

export const GalleryImageViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100rem;
  width: 100rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`
export const LinearProgressHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 0.2rem;
  z-index: 4;
  width: 100%;
`

export const PaymentStatusLabel = styled.span<{ state?: PaymentStatus }>`
  display: inline-flex;
  padding: .4rem .8rem;
  margin-left: .8rem;
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: .4rem;
  white-space: nowrap;

  ${({state}: { state?: PaymentStatus }) => {
    switch (state) {
      case PaymentStatus.NOT_INVOICED:
        return `
          background-color: ${colors.lightgray};
        `
      case PaymentStatus.PAID:
        return `
          background-color: ${colors.limegreenLighten3};
        `
      case PaymentStatus.WAITING:
        return `
          background-color: ${colors.goldLighten3};
        `
      default:
        return `
          background-color: ${colors.lightgray};
        `
    }
  }}
`

export const CallStatusLabel = styled.span<{ state?: ECallState }>`
  display: inline-flex;
  padding: .4rem .8rem;
  margin-left: .8rem;
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: .4rem;
  white-space: nowrap;

  ${({state}: { state?: ECallState }) => {
    switch (state) {
      case ECallState.NO_CALL:
        return `
          background-color: ${colors.lightgray};
        `
      case ECallState.ANSWERED:
        return `
          background-color: ${colors.limegreenLighten3};
        `
      case ECallState.NOT_ANSWERED:
        return `
          background-color: ${colors.goldLighten3};
        `
      default:
        return `
          background-color: ${colors.lightgray};
        `
    }
  }}
`

export const FilterBox = styled.div`
  position: absolute;
  top: 5.2rem;
  left: 3.2rem;
  background-color: ${colors.white};
  border-radius: 1.6rem;
  z-index: 9999;
  display: grid;
  grid-gap: .8rem;
  width: 50rem;
  padding: 1.6rem;
  border: 1px solid ${colors.grayLighten1};
  box-sizing: border-box;
`
