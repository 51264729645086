import ComponentTable                                            from "$components/ComponentTable";
import ElementButton                                             from "$elements/ElementButton";
import ElementDialog                                             from "$elements/ElementDialog";
import ElementInputText                                          from "$elements/ElementInputText";
import ElementSelect                                             from "$elements/ElementSelect";
import ElementTicketState                                        from "$elements/ElementTicketState";
import {CallStatusLabel, HeaderH1, PaymentStatusLabel, StyledTh} from "$elements/styled";
import {IPagedRequest}                                           from "$models/IPagedRequest";
import ITicket                                                   from "$models/ITicket";
import {
  useCloseContractorTicketMutation,
  useGetTicketsQuery,
  usePauseContractorTicketMutation,
  usePublishTicketMutation,
  useUnPublishTicketMutation,
}                                                                from "$services/tickets";
import EButtonColor                                              from "$types/EButtonColor";
import EButtonSize                                               from "$types/EButtonSize";
import ETicketState                                              from "$types/ETicketState";
import {defineRedirectLink, formatDateTime, formatMoney}         from "$utils/formatters";
import * as React                                                from "react";
import {useEffect, useState}                                     from "react";
import {useTranslation}                                          from "react-i18next";
import {useGetUnpublishReasonListQuery}                          from "$services/reference";
import ElementTextarea                                           from "$elements/ElementTextarea";
import ElementLink                                               from "$elements/ElementLink";
import {useAppSelector}                                          from "../../../../hooks/hooks";
import {selectUser}                                              from "$features/auth/authSlice";
import {selectIsContractors}                                     from "$features/root/rootSlice";
import styled                                                    from "styled-components";
import colors                                                    from "$scss/_colors.module.scss";
import TicketFilters
                                                                 from "$pages/PageHome/Subs/Tickets/filters/TicketFilters";
import ITicketFilters                                            from "$models/ITicketFilters";


const i18n_prefix = "page.home.tickets.";


const BadgeTicket = styled.span`
  background-color: ${colors.redLighten4};
  color: ${colors.red};
  padding: .4rem .8rem;
  border-radius: .8rem;
  justify-self: flex-start;
  align-self: flex-start;
`

const DateHolder = styled.div`
  display: grid;
  grid-gap: .4rem;
  align-items: flex-start;
  justify-content: flex-start;
`

export default function Tickets() {
  const {t} = useTranslation();
  const request = (params: IPagedRequest) => useGetTicketsQuery({...params}, {
    refetchOnMountOrArgChange: true,
    pollingInterval:           refetchAfterUnpublish ? 2000 : 0,
  });
  const [publish, {isSuccess: ticketPublished, isLoading: publishing}] = usePublishTicketMutation();
  const [unpublish, {isSuccess: ticketUnPublished, isLoading: unpublishing}] = useUnPublishTicketMutation();
  const [closeTicket, {
    isSuccess: closeTicketIsSuccess,
    isLoading: closeTicketIsLoading,
  }] = useCloseContractorTicketMutation();
  const {data: unpublishReasons} = useGetUnpublishReasonListQuery();
  const user = useAppSelector(selectUser);
  const isContractors = useAppSelector(selectIsContractors);
  const [pauseContractorsTicket, {isLoading: pauseContractorsTicketIsLoading}] = usePauseContractorTicketMutation();

  const [publishingTicket, setPublishingTicket] = useState<ITicket>();
  const [unPublishingTicket, setUnPublishingTicket] = useState<ITicket>();
  const [invoiceCode, setInvoiceCode] = useState<string | undefined>("");
  const [cancelReasonId, setCancelReasonId] = useState<number>();
  const [reasonText, setReasonText] = useState("");
  const [refetchAfterUnpublish, setRefetchAfterUnpublish] = useState(false);
  const [state, setState] = useState<ETicketState>();

  const [contractorTicketId, setContractorTicketId] = useState(0);
  const [closeTicketOpen, setCloseTicketOpen] = useState(false);
  const [cancelTicketOpen, setCancelTicketOpen] = useState(false);
  const [{closeTaskComment, closeTaskError}, setCloseTaskState] = useState({closeTaskError: "", closeTaskComment: ""});
  const [{cancelTaskComment, cancelTaskError}, setCancelTaskState] = useState({
    cancelTaskError:   "",
    cancelTaskComment: "",
  });

  const toggleCloseTicket = (id?: number) => {
    setContractorTicketId(id ?? 0);
    setCloseTicketOpen(prev => !prev);
  }

  const toggleCancelTicket = (id?: number) => {
    setContractorTicketId(id ?? 0);
    setCancelTicketOpen(prev => !prev);
  }

  const handlePublishContractorsClick = (ticketId: number) => {
    publish({ticketId});
  }

  const handleCloseTicketClick = () => {
    if (!closeTaskComment.trim()) {
      setCloseTaskState(prev => ({...prev, closeTaskError: t("common.required_field")}));
      return
    }
    closeTicket({comment: closeTaskComment, isCanceled: false, id: contractorTicketId});
  }

  const handleCancelTicketClick = () => {
    if (!cancelTaskComment.trim()) {
      setCancelTaskState(prev => ({...prev, cancelTaskError: t("common.required_field")}));
      return
    }
    closeTicket({comment: cancelTaskComment, isCanceled: true, id: contractorTicketId});
  }

  useEffect(() => {
    if (refetchAfterUnpublish) {
      setTimeout(() => {
        setRefetchAfterUnpublish(false);
      }, 2000)
    }
  }, [refetchAfterUnpublish]);

  useEffect(() => {
    setPublishingTicket(undefined);
  }, [ticketPublished])
  useEffect(() => {
    setUnPublishingTicket(undefined);
  }, [ticketUnPublished])
  useEffect(() => {
    if (closeTicketIsSuccess) {
      setCloseTicketOpen(false);
      setCancelTicketOpen(false);
      setContractorTicketId(0);
    }
  }, [closeTicketIsSuccess]);

  const resetForms = (loading: boolean) => {
    if (loading) {
      setPublishingTicket(undefined);
      setUnPublishingTicket(undefined);
      setInvoiceCode("");
    }
  }

  const publishTicket = () => {
    if (publishingTicket)
      publish({ticketId: publishingTicket.id, invoice_code: invoiceCode})
  }

  const unpublishTicket = () => {
    const cancelReason = unpublishReasons?.find(reason => reason.id === cancelReasonId)?.text;
    unpublish({ticketId: unPublishingTicket?.id as number, comment: cancelReason as string ?? reasonText});
    setRefetchAfterUnpublish(true);
  }

  const header = <tr>
    <StyledTh width={"7rem"}>№</StyledTh>
    <StyledTh width={"10rem"}>ID</StyledTh>
    <StyledTh width={"20rem"}>{t(`${i18n_prefix}columns.col_1`)}</StyledTh>
    {isContractors && <StyledTh width={"15rem"}>{t(`${i18n_prefix}columns.city`)}</StyledTh>}
    <StyledTh width={"20rem"}>{t(`${i18n_prefix}columns.col_2`)}</StyledTh>
    <StyledTh width={"20rem"}>{t(`${i18n_prefix}columns.col_3`)}</StyledTh>
    <StyledTh>{t(`${i18n_prefix}columns.col_4`)}</StyledTh>
    {isContractors && <StyledTh width={"17rem"}>{t(`${i18n_prefix}columns.payment_status`)}</StyledTh>}
    {isContractors && <StyledTh width={"17rem"}>{t(`${i18n_prefix}columns.call_status`)}</StyledTh>}
    <StyledTh width={"20rem"}>{t(`${i18n_prefix}columns.col_5`)}</StyledTh>
    {!isContractors && <StyledTh width={"20rem"}>{t(`${i18n_prefix}columns.col_6`)}</StyledTh>}
    {!isContractors && <StyledTh width={"25rem"}>{t(`${i18n_prefix}columns.col_7`)}</StyledTh>}
    <StyledTh />
  </tr>

  const selectPublishingTicket = (ticket: ITicket) => {
    setPublishingTicket({...ticket});
    setInvoiceCode(ticket.invoice_code ?? undefined);
  }
  const selectUnPublishingTicket = (ticket: ITicket) => {
    setUnPublishingTicket({...ticket});
    setCancelReasonId(undefined);
  }

  const handleChangeReasonText = (value: string) => {
    setReasonText(value);
    setCancelReasonId(undefined);
  }

  const handleChangeReasonId = (value: number) => {
    setCancelReasonId(value);
    setReasonText("");
  }

  const rowRender = (row: ITicket, index: number) => <tr
    key={`${row.id}-${index}`}
    onMouseEnter={() => setState(row.state)}
  >
    <td><span className="text nowrap">{row.number}</span></td>
    <td><ElementLink to={`/ticket/${row.id}`}>{row.id}</ElementLink></td>
    <td>
      <DateHolder>
        <span className="text nowrap">{formatDateTime(new Date(row.created_at))}</span>
        {row.is_urgent && <BadgeTicket>{t(`${i18n_prefix}contractors.is_urgent`)}</BadgeTicket>}
      </DateHolder>
    </td>
    {isContractors && <td>{row.city}</td>}
    <td><span className="text nowrap">{formatMoney(row.price)}</span></td>
    <td><span className="text keep-words">{row.service}</span></td>
    <td><ElementTicketState state={row.state} /></td>
    {isContractors && <td>
      <PaymentStatusLabel state={row.payment_status} className="mgl-0">
        {t(`common.payment_state.${row.payment_status}`)}
      </PaymentStatusLabel>
    </td>}
    {isContractors && row.call_state && <td>
      <CallStatusLabel state={row.call_state}>
        {t(`common.call_state.${row.call_state}`)}
      </CallStatusLabel>
    </td>}
    <td>
      <div className="flex dir-column">
        <span className={row.client?.name ? "text keep-words text fw-bold" : "text nowrap text fw-bold"}>
          {row.client?.name ?? row.client?.phone}
        </span>
        <span className={row.client?.phone ? "text keep-words" : "text nowrap"}>{row?.client.phone}</span>
      </div>
    </td>
    {!isContractors && <td>
      <div className="flex dir-column">
        <span className={row.specialist?.name ? "text keep-words text fw-bold" : "text nowrap text fw-bold"}>
          {user?.is_admin ? <ElementLink blank to={defineRedirectLink(row.specialist?.id as number, "specialists")}>
            {row.specialist?.name ?? row.specialist?.phone}
          </ElementLink> : row.specialist?.name ?? row.specialist?.phone}
        </span>
        <span className={row.specialist?.phone ? "text keep-words" : "text nowrap"}>{row?.specialist?.phone}</span>
      </div>
    </td>}
    {!isContractors && <td><span>{row.manager}</span></td>}
    <td className="flex end">
      {!isContractors
        ? <>
          {row.action === "publish" && <ElementButton
            buttonColor={EButtonColor.SUCCESS}
            size={EButtonSize.EXTRA_SMALL}
            onClick={() => selectPublishingTicket({...row})}
          >
            <span className="text nowrap">{t(`${i18n_prefix}publish`)}</span>
          </ElementButton>}
          {row.action === "un-publish" && <ElementButton
            buttonColor={EButtonColor.ALERT}
            size={EButtonSize.EXTRA_SMALL}
            onClick={() => selectUnPublishingTicket({...row})}
          >
            <span className="text nowrap">{t(`${i18n_prefix}unpublish`)}</span>
          </ElementButton>}
        </>
        : <>
          {row.action === "publish" && <ElementButton
            buttonColor={EButtonColor.SUCCESS}
            size={EButtonSize.EXTRA_SMALL}
            onClick={() => handlePublishContractorsClick(row.id)}
            disabled={publishing}
            loading={publishing}
          >
            <span className="text nowrap">{t(`${i18n_prefix}contractors.submit_ticket`)}</span>
          </ElementButton>}
          {row.action === "un-publish" && <div className="flex end dir-column">
            <ElementButton
              size={EButtonSize.EXTRA_SMALL}
              className="mgb-1"
              buttonColor={EButtonColor.SUCCESS}
              onClick={() => toggleCloseTicket(row.id)}
            >
              <span className="text nowrap">{t(`${i18n_prefix}contractors.close_ticket`)}</span>
            </ElementButton>
            <ElementButton
              size={EButtonSize.EXTRA_SMALL}
              onClick={() => toggleCancelTicket(row.id)}
              buttonColor={EButtonColor.ALERT}
              className="mgb-1"
            >
              <span className="text nowrap">{t(`${i18n_prefix}contractors.cancel_ticket`)}</span>
            </ElementButton>
            <ElementButton
              size={EButtonSize.EXTRA_SMALL}
              onClick={() => pauseContractorsTicket(row.id)}
              buttonColor={EButtonColor.WARNING}
              disabled={pauseContractorsTicketIsLoading}
              loading={pauseContractorsTicketIsLoading}
            >
              <span className="text black nowrap">{t(`${i18n_prefix}contractors.pause_ticket`)}</span>
            </ElementButton>
          </div>}
        </>}
    </td>
  </tr>

  const filters = (onUpdateFilter: (params: ITicketFilters) => void) => <TicketFilters
    onUpdateFilter={onUpdateFilter} />

  return <>
    <ComponentTable
      header={header}
      onLoadingChange={resetForms}
      rowRender={rowRender}
      request={request}
      filters={filters}
      state={state}
    />
    <ElementDialog isOpen={closeTicketOpen} onClosed={toggleCloseTicket}>
      <HeaderH1>{t(`${i18n_prefix}contractors.dialog.close_title`)}</HeaderH1>

      <ElementTextarea
        label={t(`${i18n_prefix}contractors.dialog.label`)}
        required
        value={closeTaskComment}
        errorMessage={closeTaskError}
        onUpdateValue={(value) => setCloseTaskState({closeTaskComment: value, closeTaskError: ""})}
        className="mgy-2"
      />

      <ElementButton
        buttonWidth="50%"
        size={EButtonSize.SMALL}
        buttonColor={EButtonColor.ALERT}
        onClick={handleCloseTicketClick}
        disabled={closeTicketIsLoading}
        loading={closeTicketIsLoading}
      >
        {t("common.close")}
      </ElementButton>
    </ElementDialog>

    <ElementDialog isOpen={cancelTicketOpen} onClosed={toggleCancelTicket}>
      <HeaderH1>{t(`${i18n_prefix}contractors.dialog.cancel_title`)}</HeaderH1>

      <ElementTextarea
        label={t(`${i18n_prefix}contractors.dialog.label`)}
        required
        value={cancelTaskComment}
        errorMessage={cancelTaskError}
        onUpdateValue={(value) => setCancelTaskState({cancelTaskComment: value, cancelTaskError: ""})}
        className="mgy-2"
      />

      <ElementButton
        buttonWidth="50%"
        size={EButtonSize.SMALL}
        buttonColor={EButtonColor.ALERT}
        onClick={handleCancelTicketClick}
        disabled={closeTicketIsLoading}
        loading={closeTicketIsLoading}
      >
        {t(`${i18n_prefix}contractors.dialog.cancel`)}
      </ElementButton>
    </ElementDialog>
    <ElementDialog
      isOpen={!!publishingTicket?.id}
      persistent
      onClosed={() => setPublishingTicket(undefined)}
    >
      <div className="text-center">
        <div className="mgb-3">
          <img
            src="/img/image.svg"
            alt=""
            width="150"
            height="152"
          />
        </div>
        <div
          className="mgb-1 text fs-18 fw-semibold"
        >{t(`${i18n_prefix}publish_message`, {id: publishingTicket?.id})}</div>
        <div
          className="mgb-3 text fs-small darkgray"
        >{t(`${i18n_prefix}publish_subtitle`, {price: formatMoney(publishingTicket?.price ?? 0)})}</div>
        <ElementInputText
          placeholder={t(`${i18n_prefix}placeholders.publish`)}
          value={invoiceCode}
          onUpdateValue={(value) => setInvoiceCode(value)}
          className="mgb-3"
        />
        <ElementButton
          buttonWidth={"100%"}
          loading={publishing}
          onClick={publishTicket}
        >{t(`${i18n_prefix}labels.publish_button`)}</ElementButton>
      </div>
    </ElementDialog>
    <ElementDialog
      isOpen={!!unPublishingTicket?.id}
      persistent
      onClosed={() => setUnPublishingTicket(undefined)}
    >
      <div className="text-center">
        <div className="mgb-3">
          <img
            src="/img/image.svg"
            alt=""
            width="150"
            height="152"
          />
        </div>
        <div
          className="mgb-3 text fs-18 fw-semibold"
        >{t(`${i18n_prefix}unpublish_message`, {id: unPublishingTicket?.id})}</div>
        <ElementSelect
          required
          label={t(`${i18n_prefix}placeholders.unpublish`)}
          placeholder={t("common.select_placeholder")}
          value={cancelReasonId}
          onUpdateValue={value => handleChangeReasonId(+value)}
          items={unpublishReasons ?? []}
          className="mgb-3"
        />
        <ElementTextarea
          placeholder={t(`${i18n_prefix}placeholders.reason`)}
          className="mgb-3"
          value={reasonText}
          onUpdateValue={value => handleChangeReasonText(value)}
        />
        <ElementButton
          buttonWidth={"100%"}
          buttonColor={EButtonColor.ALERT}
          loading={unpublishing}
          onClick={unpublishTicket}
        >{t(`${i18n_prefix}labels.unpublish_button`)}</ElementButton>
      </div>
    </ElementDialog>
  </>
}
