import * as React    from "react";
import {useRef}      from "react";
import {useLocation} from "react-router-dom";
import ETabType      from "$types/ETabType";
import ITab          from "$models/ITab";
import classNames    from "classnames";
import {Tab, Tabs}   from "./styles";


interface IProps {
  tabs: ITab[],
  type?: ETabType,
}


export default function ElementTabs({tabs, type = ETabType.DEFAULT}: IProps) {
  const location = useLocation();
  const tabsRef = useRef<HTMLDivElement>(null);

  return <Tabs
    ref={tabsRef}
    onWheel={(e) => {
      if (!tabsRef.current) return;
      tabsRef.current.scrollLeft += e.deltaY;
    }}
    className={classNames("styled-scrollbar", {"tabs--pill": type === ETabType.PILLS})}
  >
    {tabs?.map((tab, index) => {
      const isActive =
        location.pathname === tab.to ||
        (!tab.excludeSubPaths && location.pathname.startsWith(tab.to))
      return <Tab
        to={tab.to}
        key={tab.to}
        blank={tab.blank}
        active={isActive}
        className={classNames({"mgr-2": index < tabs?.length - 1, "tab--active": tab.active})}
      >{tab.text}</Tab>
    })}
  </Tabs>
}
