import React, {useEffect, useState}     from "react";
import {
  useContractorsTicketChangePriceMutation,
  useGetTicketByIdQuery,
  usePaymentRequestMutation,
}                                       from "$services/tickets";
import {useParams}                      from "react-router";
import LayoutWithHeader                 from "$layouts/LayoutWIthHeader";
import ElementPageHeader                from "$elements/ElementPageHeader";
import {
  CallStatusLabel,
  Card,
  HeaderH1, Hr,
  LinearProgressHolder,
  PaymentStatusLabel,
  TableHeader,
}                                       from "$elements/styled";
import ElementTable                     from "$elements/ElementTable";
import {
  defineRedirectLink,
  formatDateTime,
}                                       from "$utils/formatters";
import ElementTicketState               from "$elements/ElementTicketState/ElementTicketState";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {selectUser}                     from "$features/auth/authSlice";
import ElementLink                      from "$elements/ElementLink";
import ElementGallery                   from "$elements/ElementGallery";
import styled                           from "styled-components";
import {useTranslation}                 from "react-i18next";
import classNames                       from "classnames";
import {
  addToastMessage,
  selectIsContractors,
  setCallbackId,
}                                       from "$features/root/rootSlice";
import ElementInputText                 from "$elements/ElementInputText";
import EMoleculeSize                    from "$types/EMoleculeSize";
import ElementButton                    from "$elements/ElementButton";
import EButtonSize                      from "$types/EButtonSize";
import EMessageType                     from "$types/EMessageType";
import EButtonColor                     from "$types/EButtonColor";
import ElementDialog                    from "$elements/ElementDialog";
import EButtonVariant                   from "$types/EButtonVariant";
import ETicketState                     from "$types/ETicketState";
import ReactAudioPlayer                 from "react-audio-player";


const CARD_WIDTH = 1000;
const i18n_prefix = "page.ticket."

const DescriptionHolder = styled.div`
  max-height: 30rem;
  overflow-y: auto;
`

const PriceHolder = styled.div`
  div:first-child {
    flex: 1 1 0;
  }
`


function formatDescription(value: string) {
  if (!value) return "";
  if (value.includes("\n")) {
    return <div className="flex dir-column">
      {value.split("\n").map((item, index) => <span key={index}>{item}</span>)}
    </div>
  }
  return <span>{value}</span>;
}

const PageTicket = () => {
  const {t} = useTranslation();
  const {id: paramId} = useParams();
  const id = paramId ? parseInt(paramId) : -1;
  const user = useAppSelector(selectUser);
  const isContractors = useAppSelector(selectIsContractors);
  const dispatch = useAppDispatch();

  const {data: ticket, isLoading, isFetching} = useGetTicketByIdQuery(id, {refetchOnMountOrArgChange: true});
  const [updateTicketPrice, {
    isLoading: updateTicketPriceIsLoading,
    isSuccess,
  }] = useContractorsTicketChangePriceMutation();
  const [createPaymentRequest, {
    isLoading: createPaymentRequestIsLoading,
    isSuccess: createPaymentRequestIsSuccess,
  }] = usePaymentRequestMutation();

  const [price, setPrice] = useState<string>("");
  const [openRequestPayment, setOpenRequestPayment] = useState(false);

  const handleToggleRequestPayment = () => setOpenRequestPayment(prev => !prev);

  const handleUpdateTicketPriceClick = () => {
    if (!price) return;
    updateTicketPrice({id, price: +price});
  }

  const handleCreatePaymentRequestClick = () => {
    if (!price) return;
    createPaymentRequest({id, price: +price});
  }

  const handleSetCallbackId = () => {
    if (ticket?.id) {
      dispatch(setCallbackId(ticket?.id));
    }
  }

  useEffect(() => {
    if (ticket) {
      const ticketPrice = ticket.price ? `${ticket.price}` : "";
      setPrice(ticketPrice);
    }
  }, [ticket, isLoading, isFetching]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(addToastMessage({
        type:    EMessageType.SUCCESS,
        message: t(`${i18n_prefix}price_update_success`),
      }));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (createPaymentRequestIsSuccess) {
      dispatch(addToastMessage({
        type:    EMessageType.SUCCESS,
        message: t(`${i18n_prefix}payment_request.toast`),
      }));
      handleToggleRequestPayment();
    }
  }, [createPaymentRequestIsSuccess]);

  const header = <ElementPageHeader title={t(`${i18n_prefix}header`, {id: ticket?.id})} backLink={"/"} />

  return <LayoutWithHeader header={header}>
    <Card maxWidth={CARD_WIDTH} className="mga-4">
      <TableHeader className="flex v-centered pdx-2 pos-r">
        <div className="flex-item">{t(`${i18n_prefix}about`)}</div>
        {ticket && <ElementTicketState state={ticket.state} />}
        {isContractors &&
          <PaymentStatusLabel state={ticket?.payment_status}>
            {t(`common.payment_state.${ticket?.payment_status}`)}
          </PaymentStatusLabel>}
        {isContractors &&
          <CallStatusLabel state={ticket?.call_state}>
            {t(`common.call_state.${ticket?.call_state}`)}
          </CallStatusLabel>}
        {isLoading && <LinearProgressHolder className={classNames("horizontal-progress", {"active": true})} />}
      </TableHeader>
      {ticket && <ElementTable disableDragScroll>
        <tbody>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_1`)}</td>
          <td>{formatDateTime(new Date(ticket.created_at))}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_2`)}</td>
          <td>{ticket.connect_at ? formatDateTime(new Date(ticket.connect_at)) : ""}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_3`)}</td>
          <td>{ticket.selected_at ? formatDateTime(new Date(ticket.selected_at as string)) : ""}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_4`)}</td>
          <td>{ticket.closed_at ? formatDateTime(new Date(ticket.closed_at)) : ""}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.price`)}</td>
          <td>
            {isContractors
              ? <PriceHolder className="flex v-centered">
                <ElementInputText
                  type="number"
                  size={EMoleculeSize.Small}
                  value={price}
                  onUpdateValue={(value) => setPrice(value)}
                />
                <ElementButton
                  size={EButtonSize.MEDIUM}
                  className="mgl-1"
                  disabled={price.trim() === "" || updateTicketPriceIsLoading}
                  loading={updateTicketPriceIsLoading}
                  onClick={handleUpdateTicketPriceClick}
                >
                  {t("common.edit")}
                </ElementButton>
              </PriceHolder>
              : ticket.price}
          </td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_5`)}</td>
          <td>{ticket.client ? `${ticket.client.name ?? ""}, ${ticket.client.phone ?? ""}` : ""}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_6`)}</td>
          <td>{ticket.address ? <span>{`${ticket?.address?.city}, ${ticket?.address?.address}`}</span> : <></>}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_7`)}</td>
          <td>
            <DescriptionHolder className="styled-scrollbar">
              {formatDescription(ticket.description)}
            </DescriptionHolder>
          </td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_8`)}</td>
          <td>{ticket.review_mark}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_9`)}</td>
          {ticket.specialist !== null && <td>
            {user?.is_admin ?
              <ElementLink blank to={defineRedirectLink(ticket.specialist.id, "specialists")}>
                {ticket.specialist.full_name}
              </ElementLink> :
              ticket.specialist.full_name}
          </td>}
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_10`)}</td>
          <td>{ticket.comment}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_11`)}</td>
          <td><ElementGallery photos={ticket?.photos.map((digest) => ({digest})) ?? []} /></td>
        </tr>
        {user?.is_admin && <tr>
          <td>{t(`${i18n_prefix}rows.row_12`)}</td>
          <td>
            <ElementLink blank to={defineRedirectLink(ticket.id, "tickets")}>{ticket.id}</ElementLink>
          </td>
        </tr>}
        {isContractors && !!ticket.calls.length && <tr>
          <td>{t(`${i18n_prefix}rows.calls`)}</td>
          <td className="pdx-0">
            {ticket.calls.map((call, index, array) => {
              return <div key={index}>
                <div className="flex v-centered mgr-2">
                  <span className="text fw-semibold">{t(`common.callback_status.${call.state}`)}</span>
                  {call.records ? call.records.map((record) => {
                    return <ReactAudioPlayer
                      key={`${record.id}`}
                      src={record.link}
                      controls
                    />
                  }) : null}
                </div>
                {array.length - 1 !== index && <Hr />}
              </div>
            })}
          </td>
        </tr>}
        <tr>
          <td>
            <ElementButton
              buttonColor={EButtonColor.SUCCESS}
              onClick={handleToggleRequestPayment}
              disabled={ticket.state !== ETicketState.ACTIVE}
            >
              <span className="text nowrap">{t(`${i18n_prefix}payment_request.button`)}</span>
            </ElementButton>
          </td>
          <td />
        </tr>
        {isContractors && ticket.id && <tr>
          <td />
          <td className="flex end">
            <ElementButton
              buttonColor={EButtonColor.SUCCESS}
              onClick={handleSetCallbackId}
            >
              Позвонить
            </ElementButton>
          </td>
        </tr>}
        </tbody>
      </ElementTable>}
    </Card>

    <ElementDialog isOpen={openRequestPayment} onClosed={handleToggleRequestPayment} persistent>
      <HeaderH1>{t(`${i18n_prefix}payment_request.title`)}</HeaderH1>

      <ElementInputText
        label={t(`${i18n_prefix}rows.price`)}
        type="number"
        size={EMoleculeSize.Small}
        value={price}
        onUpdateValue={(value) => setPrice(value)}
        className="mgy-2"
      />
      <div className="flex">
        <ElementButton
          variant={EButtonVariant.OUTLINED}
          buttonWidth="50%"
          className="mgr-1-2"
          onClick={handleToggleRequestPayment}
          loading={createPaymentRequestIsLoading}
          disabled={createPaymentRequestIsLoading}
        >
          {t("common.cancel")}
        </ElementButton>
        <ElementButton
          buttonWidth="50%"
          className="mgl-1-2"
          loading={createPaymentRequestIsLoading}
          disabled={createPaymentRequestIsLoading}
          onClick={handleCreatePaymentRequestClick}
        >
          {t(`${i18n_prefix}payment_request.confirm`)}
        </ElementButton>
      </div>
    </ElementDialog>
  </LayoutWithHeader>
}

export default PageTicket;