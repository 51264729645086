import ElementLink from "$elements/ElementLink/ElementLink";
import colors      from "$scss/_colors.module.scss";
import scssVars    from "$scss/_variables.module.scss";
import styled      from "styled-components";
import * as React  from "react";
import {ReactNode} from "react";

const _Tabs = React.forwardRef<HTMLDivElement, {
  className: string,
  children: ReactNode,
  onWheel: (e: React.WheelEvent<HTMLDivElement>) => void
}>(({
  className,
  children,
  onWheel,
}, ref) => (
  <div
    className={className}
    ref={ref}
    onWheel={onWheel}
  >
    {children}
  </div>
));


export const Tab = styled(ElementLink)`
  color: ${({active}: { active?: boolean }) => active ? colors.dodgerblue : colors.darkgray};
  font-size: 1.4rem;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: 1.6rem 0 0 0;
  margin-bottom: 1.6rem;
  position: relative;

  &:hover {
    text-decoration: none !important;
  }

  &:after {
    content: '';
    opacity: ${({active}: { active?: boolean }) => active ? 1 : 0};
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 2px;
    background: ${colors.dodgerblue};
  }
`

export const Tabs = styled(_Tabs)`
  max-width: 100%;
  overflow: auto;
  white-space: nowrap;

  &.tabs--pill {
    border-radius: .8rem;
    padding: .4rem;
    background-color: ${colors.grayLighten1};

    a {
      border-radius: .6rem;
      background-color: transparent;
      padding: .8rem 1.6rem;
      color: ${colors.black};
      font-weight: 400;
      line-height: 2rem;
      font-size: 1.5rem;
      letter-spacing: -0.24px;
      transition: background-color ${scssVars.transitionDuration} ${scssVars.transitionCurve}, color ${scssVars.transitionDuration} ${scssVars.transitionCurve};

      &:after {
        content: none
      }

      &.tab--active {
        color: ${colors.black};
        background-color: ${colors.white};
        filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.12)) drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.04));
      }
    }
  }
`