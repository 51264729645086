import Sidebar                  from "$components/Sidebar";
import {selectIsAuthenticated}  from "$features/auth/authSlice";
import {
  removeToastMessage,
  selectRedirect,
  selectToastMessages,
}                               from "$features/root/rootSlice";
import RootRoutes               from "$routes/RootRoutes";
import classNames               from "classnames";
import * as React               from "react";
import {useEffect}              from "react";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {useSelector}            from 'react-redux';
import {useNavigate}            from "react-router-dom";
import {toast, ToastContainer}  from 'react-toastify';
import {useNotificationCenter}  from "react-toastify/addons/use-notification-center";
import styled                   from "styled-components";
import {useAppDispatch}         from "./hooks/hooks";
import CallToClient             from "$components/CallToClient";

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  overflow: hidden;
  grid-template-columns:
           0
          [pageLeft sidebarLeft] auto
          [sidebarRight contentLeft] 1fr
          [contentRight pageRight] 0;
  grid-template-rows: [pageTop sidebarTop contentTop] 1fr [pageBottom sidebarBottom contentBottom];
`

const SidebarHolder = styled.div`
  grid-column: sidebarLeft / sidebarRight;
  grid-row: sidebarTop /sidebarBottom;
`

export default function App() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const messages = useSelector(selectToastMessages);
  const {notifications} = useNotificationCenter();
  const redirect = useSelector(selectRedirect);

  useEffect(() => {
    messages.forEach(message => {
      const toastId = `toast-${message.id}`;
      if (!notifications.find(n => n.id === toastId))
        toast[message.type](message.message, {
          toastId,
          onClose: () => {
            dispatch(removeToastMessage(message.id))
          },
        });
    })
  }, [messages])

  useEffect(() => {
    if (redirect && location.href !== redirect.to) {
      navigate(redirect.to, {replace: redirect.replace});
    }
  }, [redirect]);

  return <HelmetProvider>
    <div style={{position: "relative"}}>
      <Helmet>
        <title>Админ панель</title>
      </Helmet>
      <CallToClient/>
      <Root
        className={classNames('horizontal-progress position-top',
          // {active: loading}
        )}
      >
        {isAuthenticated && <SidebarHolder>
          <Sidebar />
        </SidebarHolder>}
        <RootRoutes />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop
        />
      </Root>
    </div>
  </HelmetProvider>
}
