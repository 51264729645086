import classNames     from "classnames";
import * as React     from "react";
import styled         from "styled-components";
import EButtonType    from "$types/EButtonType";
import EButtonColor   from "$types/EButtonColor";
import colors         from "$scss/_colors.module.scss";
import variables      from "$scss/_variables.module.scss";
import EButtonSize    from "$types/EButtonSize";
import EButtonVariant from "$types/EButtonVariant";
import {rgba}         from "$utils/formatters";
import ElementLink    from "$elements/ElementLink";

interface IProps {
  blank?: boolean;
  children?: React.ReactNode;
  className?: string;
  buttonColor?: EButtonColor;
  disabled?: boolean;
  draggable?: boolean,
  icon?: string;
  leftImage?: string;
  loading?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onDragEnd?: (event: React.MouseEvent) => void;
  onDragEnter?: (event: React.MouseEvent) => void;
  onDragStart?: (event: React.MouseEvent) => void;
  outlinedIcon?: boolean;
  rightIcon?: string;
  rightImage?: string;
  size?: EButtonSize;
  tabIndex?: number,
  to?: string;
  type?: EButtonType;
  variant?: EButtonVariant;
  buttonWidth?: string,
}

interface IButtonImage {
  size?: EButtonSize,
  image: "left" | "right",
  buttonWidth?: string,
  hasChildren?: boolean
}

// <editor-fold desc="styled elements">
const Anchor = styled(ElementLink)`
  display: inline-block;
  width: ${({width}: { width?: string }) => width ? width : "auto"};
  text-decoration: none;
  color: ${({buttonColor}: IProps) => buttonColor && buttonColor};

  :hover {
    text-decoration: none;
  }
`

const IconHolder = styled.span`
  font-size: ${({size}: IProps) => (size === EButtonSize.LARGE && "3.2rem")
    || (size === EButtonSize.EXTRA_SMALL && "1.6rem")
    || "2.4rem"
  }`

const ImageHolder = styled.img`
  margin-right: ${({buttonWidth, image}: IButtonImage) => (!buttonWidth && (image === "left") && ".8rem")};
  margin-left: ${({buttonWidth, image}: IButtonImage) => (!buttonWidth && (image === "right") && ".8rem")};

  ${({hasChildren}: IButtonImage) => !hasChildren && `
    margin: 0;
  `}

  ${({size}: IButtonImage) => {
    if (size === EButtonSize.LARGE) {
      return `
        width: 3.2rem;
        height: 3.2rem;
      `
    }
    if (size === EButtonSize.EXTRA_SMALL) {
      return `
        width: 1.6rem;
        height: 1.6rem;
      `
    }
    return `
      width: 2.4rem;
      height: 2.4rem;
    `
  }
  }`

const StyledButton = styled.button<{ hasChildren: boolean } & IProps>`
  width: ${({buttonWidth}: { buttonWidth?: string }) => buttonWidth ? buttonWidth : "auto"};
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: ${variables.borderRadius};
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: -0.01em;
  transition: ${variables.transitionDuration} ${variables.transitionCurve};

  ${({buttonColor}: IProps) => buttonColor === EButtonColor.INFO && `
    background-color: ${colors.white} !important;
    color: ${buttonColor} !important;
  `};

  ${({variant, buttonColor}: IProps) => {
    if (variant === EButtonVariant.FILLED) {
      return `
        background-color: ${buttonColor};
        color: ${colors.white};
        &:hover {
          background-color: ${buttonColor && colors[`${buttonColor}Lighten1`]};
        };
        &:active {
          background-color: ${buttonColor && colors[`${buttonColor}Darken1`]};
        };
        &:focus {
          outline: none;
          box-shadow: 0 0 0 4px ${rgba(`${buttonColor && colors[`${buttonColor}`]}`, "16%")};
        };
        &:disabled {
          background-color: ${colors.lightgray};
          color: ${colors.darkgray} !important;
        };
        &.active {
          &:before {
            background-image: linear-gradient(to right, transparent 50%,
            ${colors.white} 50%, ${colors.white} 60%, transparent 60%, transparent 71.5%,
            ${colors.white} 71.5%, ${colors.white} 84%, transparent 84%);
          }
        }
      `
    }
    if (variant === EButtonVariant.TEXT) {
      return `
        background-color: transparent;
        color: ${buttonColor && colors[buttonColor]};
        &:hover {
          color: ${buttonColor && colors[`${buttonColor}Lighten1`]};
        };
        &:active {
          color: ${buttonColor && colors[`${buttonColor}Darken1`]};
        };
        &:focus {
          outline: none;
          box-shadow: 0 0 0 4px ${rgba(`${buttonColor && colors[`${buttonColor}`]}`, "16%")};
          background-color: ${colors.whitesmoke};
        };
        &:disabled {
          color: ${colors.lightgray};
        };
        &.active {
          &:before {
            background-image: linear-gradient(to right, transparent 50%,
            ${buttonColor && colors[buttonColor]} 50%, ${buttonColor && colors[buttonColor]} 60%,
            transparent 60%, transparent 71.5%, ${buttonColor && colors[buttonColor]} 71.5%,
            ${buttonColor && colors[buttonColor]} 84%, transparent 84%);
          }
        };
      `
    }
    if (variant === EButtonVariant.LIGHT) {
      return `
        background-color: ${buttonColor && colors[`${buttonColor}Lighten4`]};
        color: ${buttonColor && colors[buttonColor]};
        &:hover {
          color: ${buttonColor && colors[`${buttonColor}Lighten1`]};
        };
        &:active {
          color: ${buttonColor && colors[`${buttonColor}Darken1`]};
        };
        &:focus {
          outline: none;
          box-shadow: 0 0 0 4px ${rgba(`${buttonColor && colors[`${buttonColor}`]}`, "16%")};
        };
        &:disabled {
          background-color: ${colors.lightgray};
          color: ${colors.darkgray};
        };
        &.active {
          &:before {
            background-image: linear-gradient(to right, transparent 50%,
             ${buttonColor && colors[buttonColor]} 50%, ${buttonColor && colors[buttonColor]} 60%,
             transparent 60%, transparent 71.5%, ${buttonColor && colors[buttonColor]} 71.5%,
             ${buttonColor && colors[buttonColor]} 84%, transparent 84%);
          }
        };
      `
    }
    return `
      background-color: transparent;
      color: ${buttonColor};
      border: .1rem solid ${buttonColor};
      &:hover {
        border: .1rem solid ${buttonColor && colors[`${buttonColor}Lighten1`]};
        color: ${buttonColor && colors[`${buttonColor}Lighten1`]};
      };
      &:active {
        border: .1rem solid ${buttonColor && colors[`${buttonColor}Darken1`]};
        color: ${buttonColor && colors[`${buttonColor}Darken1`]};
      };
      &:focus {
        outline: none;
        background-color: ${colors.whitesmoke};
        border: .1rem solid ${buttonColor && colors[buttonColor]};
        box-shadow: 0 0 0 4px ${rgba(`${buttonColor && colors[`${buttonColor}`]}`, "16%")};
      }
      &:disabled {
        background-color: transparent;
        border: .1rem solid ${colors.lightgray};
        color: ${colors.lightgray};
      };
      &.active {
          &:before {
            background-image: linear-gradient(to right, transparent 50%,
            ${buttonColor} 50%, ${buttonColor} 60%,
            transparent 60%, transparent 71.5%, ${buttonColor} 71.5%,
            ${buttonColor} 84%, transparent 84%);
          }
        };
    `
  }
  };

  ${({size, hasChildren, variant}: IProps & { hasChildren: boolean }) => {
    if (size === EButtonSize.LARGE) {
      return `
        padding: ${!hasChildren ? "1.6rem" : "1.6rem 2.4rem"};
        font-size: 2.4rem;
        line-height: 3.2rem;
      `
    }
    if (size === EButtonSize.MEDIUM) {
      if (variant === EButtonVariant.OUTLINED) {
        return `
          padding: ${!hasChildren ? "1.2rem" : "1.1rem 2.3rem"};
        `
      }
      return `
        padding: ${!hasChildren ? "1.2rem" : "1.2rem 2.4rem"};
        font-size: 1.6rem;
      `
    }
    if (size === EButtonSize.SMALL) {
      return `
        padding: ${!hasChildren ? ".8rem" : ".8rem 2.4rem"};
        font-size: 1.4rem;
      `
    }
    return `
      padding: ${!hasChildren ? ".80rem" : ".8rem 2.4rem"};
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.01em;
      border-radius: .8rem;
    `
  }
  }
`
// </editor-fold>

export default function ElementButton({
  blank = false,
  children,
  className = "",
  buttonColor = EButtonColor.PRIMARY,
  disabled = false,
  draggable = false,
  icon,
  leftImage,
  loading = false,
  onClick,
  onDragEnd,
  onDragEnter,
  onDragStart,
  outlinedIcon,
  rightIcon,
  rightImage,
  size = EButtonSize.MEDIUM,
  tabIndex,
  to = undefined,
  type = EButtonType.BUTTON,
  variant = EButtonVariant.FILLED,
  buttonWidth = undefined,
}: IProps) {

  // let strType: 'reset' | 'submit' | 'button' | undefined;
  //
  // if ([EButtonType.BUTTON, EButtonType.SUBMIT, EButtonType.RESET].includes(type)) {
  //   strType = type as 'reset' | 'submit' | 'button' | undefined;
  // }

  const hasChildren = !!children

  const currentChild =
    <span className="flex">
      {icon &&
        <IconHolder
          size={size}
          className={classNames("material-icons", {outlined: outlinedIcon})}
        >{icon}</IconHolder>
        || leftImage &&
        <ImageHolder
          hasChildren={hasChildren}
          width={buttonWidth}
          image="left"
          size={size}
          src={leftImage}
        />
      }
      {children}
      {rightIcon &&
        <IconHolder
          size={size}
          className={classNames("material-icons", {outlined: outlinedIcon})}
        >{rightIcon}</IconHolder>
        || rightImage &&
        <ImageHolder
          hasChildren={hasChildren}
          buttonWidth={buttonWidth}
          image="right"
          size={size}
          src={rightImage}
        />
      }
    </span>

  return (to ? <Anchor
      to={to}
      //@ts-ignore
      draggable={draggable}
      onDragEnd={onDragEnd}
      buttonWidth={buttonWidth}
      loading={loading}
      tabIndex={tabIndex}
      onDragEnter={onDragEnter}
      onDragStart={onDragStart}
      blank={blank}
    >
      <ElementButton
        buttonColor={buttonColor}
        buttonWidth={buttonWidth}
        disabled={disabled}
        leftImage={leftImage}
        rightImage={rightImage}
        icon={icon}
        rightIcon={rightIcon}
        size={size}
        variant={variant}
        className={className + ` horizontal-progress ${loading ? "active" : ""}`}
      >
        {children}
      </ElementButton>
    </Anchor> :
    <StyledButton
      hasChildren={hasChildren}
      disabled={disabled}
      draggable={draggable}
      buttonWidth={buttonWidth}
      size={size}
      tabIndex={tabIndex}
      variant={variant}
      leftImage={leftImage}
      rightImage={rightImage}
      icon={icon}
      rightIcon={rightIcon}
      buttonColor={buttonColor}
      onDragEnd={onDragEnd}
      onDragEnter={onDragEnter}
      onDragStart={onDragStart}
      /* eslint-disable-next-line react/button-has-type */
      //@ts-ignore
      type={type ?? "button"}
      className={className + ` horizontal-progress ${loading ? "active" : ""}`}
      onClick={onClick}
    >{currentChild}</StyledButton>)
}

ElementButton.defaultProps = {
  type: EButtonType.BUTTON,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick:  () => {
  },
  disabled: false,
  loading:  false,
  to:       null,
  blank:    false,
}
