import React, {useEffect, useState}                       from "react";
import ElementButton                                      from "$elements/ElementButton";
import EButtonColor                                       from "$types/EButtonColor";
import {
  ECallStatus,
  useFinishCallbackMutation,
  useLazyCallStateQuery,
  useLazyStartCallbackQuery,
}                                                         from "$services/callback";
import {addToastMessage, selectCallbackId, setCallbackId} from "$features/root/rootSlice";
import EMessageType                                       from "$types/EMessageType";
import {useTranslation}                                   from "react-i18next";
import ElementTimer                                       from "$elements/ElementTimer";
import EButtonSize                                        from "$types/EButtonSize";
import api                                                from "$services/api";
import {useAppDispatch, useAppSelector}                   from "../../hooks/hooks";
import {
  CloseButton,
  CallBackContent,
  Header,
  CallbackModalHeader,
  CallState,
}                                                         from "./styles";


const i18n_prefix = "component.call_to_client.";
export default function CallToClient() {
  const dispatch = useAppDispatch();
  const callbackId = useAppSelector(selectCallbackId);
  const {t} = useTranslation();

  const [toggle, setToggle] = useState(true);
  const [callConnected, setCallConnected] = useState(false);
  const [callDisconnected, setCallDisconnected] = useState(false);
  const [callCounter, setCallCounter] = useState(0);
  const [talkingTime, setTalkingTime] = useState("");

  const [call, {data: callData, isLoading: startingToCall}] = useLazyStartCallbackQuery();
  const [requestCallState, {data: callState}] = useLazyCallStateQuery({pollingInterval: !callDisconnected ? 1000 : 0});
  const [finishCall, {
    isLoading: finishTaskLoading,
    // isSuccess: closeFinishTaskDialog,
  }] = useFinishCallbackMutation();

  const callRequest = async () => {
    setCallDisconnected(false);
    if (!callbackId) return;
    await call(callbackId, false);
    setCallCounter(callCounter + 1);
  }

  const hangUp = async () => {
    try {
      const response = await fetch("http://127.0.0.1:4059/disconnectcall");
      console.log(response);
    } catch (e) {
      console.log(e)
    }
  }

  const handleFinishTaskClick = () => {
    if (callData?.call_id) {
      finishCall({call_id: callData.call_id});
    }
  }

  const handleCloseCallToClient = () => {
    dispatch(setCallbackId(null));
    dispatch(api.util.resetApiState());
  }

  useEffect(() => {
    if (callData) {
      callData.error_message && dispatch(addToastMessage({
        message: callData.error_message,
        type:    EMessageType.ERROR,
      }));
      callData.is_ok && requestCallState(callData.call_id);
    }
  }, [callCounter]);

  useEffect(() => {
    const disconnected = callState?.status === ECallStatus.DISCONNECTED
      || callState?.status === ECallStatus.BUSY
      || callData?.status === ECallStatus.DISCONNECTED
      || callData?.status === ECallStatus.BUSY
      || callState?.is_error === true;
    setCallDisconnected(disconnected);
  }, [callState, callData]);

  useEffect(() => {
    if (callState?.is_error && callState.error_message) {
      dispatch(addToastMessage({type: EMessageType.ERROR, message: callState.error_message}));
    }
    if (!callConnected) {
      setCallConnected(callState?.status === ECallStatus.CONNECTED);
    }
  }, [callState]);

  if (!callbackId) return null;

  return <>
    <CallbackModalHeader toggle={toggle} onClick={() => setToggle(!toggle)}>
      <div className="flex centered v-centered pos-r">
        {toggle && <CloseButton
          onClick={(event) => {
            event.stopPropagation();
            handleCloseCallToClient();
          }}
        >
          close
        </CloseButton>}
        {toggle ? <Header className="text-center text fs-large fw-bold">Звонок</Header>
          : <span className="material-icons text green">call</span>}
      </div>
      {toggle && <CallBackContent>
        <CallState>
          <div>
            {t(`${i18n_prefix}labels.call_state`)}
          </div>
          <div className="text primary">
            {(!callData || callData?.is_error) && t(`${i18n_prefix}statuses.waiting_to_call`)}
            {callState && callState.is_error && callState.error_message}
            {callData && !callData.is_error && !callState && <ElementTimer
              timerMessage={(time: string) => t(`${i18n_prefix}statuses.initialization`, {timer: time})}
            />}
            {callState?.status === ECallStatus.DIALED && <ElementTimer
              timerMessage={(time: string) => t(`${i18n_prefix}statuses.calling`, {timer: time})}
            />}
            {callState?.status === ECallStatus.CONNECTED && <ElementTimer
              timerMessage={(time: string) => {
                setTalkingTime(time);
                return t(`${i18n_prefix}statuses.talking`, {timer: time})
              }}
            />}
            {callState?.status === ECallStatus.DISCONNECTED && t(`${i18n_prefix}statuses.call_ended`, {timer: talkingTime})}
          </div>
        </CallState>
        {!callData?.is_ok && <ElementButton
          buttonWidth="100%"
          buttonColor={EButtonColor.SUCCESS}
          loading={startingToCall}
          onClick={(event) => {
            event.stopPropagation();
            callRequest()
          }}
        >
          {t(`${i18n_prefix}labels.call`)}
        </ElementButton>}

        {callState?.status === ECallStatus.CONNECTED && <div className="flex-item">
          <ElementButton
            buttonWidth="100%"
            size={EButtonSize.SMALL}
            buttonColor={EButtonColor.ALERT}
            onClick={(event) => {
              event.stopPropagation();
              hangUp();
            }}
          >
            {t(`${i18n_prefix}labels.finish_call`)}
          </ElementButton>
        </div>}

        {callState?.status === ECallStatus.DISCONNECTED && callConnected && <>
          <div className="flex-item">
            <ElementButton
              buttonWidth="100%"
              loading={finishTaskLoading}
              onClick={(event) => {
                event.stopPropagation();
                handleFinishTaskClick();
              }}
            >
            </ElementButton>
          </div>
        </>}
      </CallBackContent>}
    </CallbackModalHeader>
  </>
};