import styled   from "styled-components";
import colors   from "$scss/_colors.module.scss";
import scssVars from "$scss/_variables.module.scss";

export const CallbackModalHeader = styled.div<{ toggle: boolean }>`
  z-index: 9999;
  position: fixed;
  right: 1.6rem;
  bottom: 6.4rem;
  width: ${({toggle}: { toggle: boolean }) => toggle ? "50rem" : "10rem"};
  background-color: ${({toggle}: { toggle: boolean }) => {
    if (!toggle) {
      return `${colors.grayDarken1}`;
    }
    return
  }};
  border-radius: ${({toggle}: { toggle: boolean }) => !toggle && `${scssVars.borderRadius}`};
  cursor: pointer;
`

export const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1001;
  font-family: "Material Icons";
  font-size: 2.4rem;
  height: 2.4rem;
  width: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Header = styled.div`
  width: 100%;
  background-color: ${colors.grayDarken1};
  border-radius: ${scssVars.borderRadius} ${scssVars.borderRadius} 0 0;
  padding: .8rem;
`

export const CallBackContent = styled.div`

  padding: 0.8rem .8rem .8rem;
  background-color: ${colors.white};
  border-radius: 0 0 ${scssVars.borderRadius} ${scssVars.borderRadius};
`

export const CallState = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem .8rem;

  div:first-child {
    width: 30%;
    margin-right: .8rem;
  }
  div:nth-child(2) {
    text-align: right;
  }
`